var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/constants.ts
var constants_exports = {};
__export(constants_exports, {
  DEFAULT_DEMO_MODULE_EXTENSIONS: () => DEFAULT_DEMO_MODULE_EXTENSIONS,
  DEFAULT_DEMO_PLAIN_TEXT_EXTENSIONS: () => DEFAULT_DEMO_PLAIN_TEXT_EXTENSIONS,
  FS_CACHE_DIR: () => FS_CACHE_DIR,
  LOCAL_DUMI_DIR: () => LOCAL_DUMI_DIR,
  LOCAL_PAGES_DIR: () => LOCAL_PAGES_DIR,
  LOCAL_THEME_DIR: () => LOCAL_THEME_DIR,
  PICKED_PKG_FIELDS: () => PICKED_PKG_FIELDS,
  PREFERS_COLOR_ATTR: () => PREFERS_COLOR_ATTR,
  PREFERS_COLOR_LS_KEY: () => PREFERS_COLOR_LS_KEY,
  SHOULD_SKIP_LIVEDEMO_ERROR: () => SHOULD_SKIP_LIVEDEMO_ERROR,
  SP_ROUTE_PREFIX: () => SP_ROUTE_PREFIX,
  THEME_PREFIX: () => THEME_PREFIX,
  USELESS_TMP_FILES: () => USELESS_TMP_FILES,
  VERSION_2_DEPRECATE_SOFT_BREAKS: () => VERSION_2_DEPRECATE_SOFT_BREAKS,
  VERSION_2_LEVEL_NAV: () => VERSION_2_LEVEL_NAV
});
module.exports = __toCommonJS(constants_exports);
var LOCAL_DUMI_DIR = ".dumi";
var LOCAL_THEME_DIR = `${LOCAL_DUMI_DIR}/theme`;
var LOCAL_PAGES_DIR = `${LOCAL_DUMI_DIR}/pages`;
var THEME_PREFIX = "dumi-theme-";
var SP_ROUTE_PREFIX = "~";
var PREFERS_COLOR_ATTR = "data-prefers-color";
var PREFERS_COLOR_LS_KEY = "dumi:prefers-color";
var PICKED_PKG_FIELDS = {
  name: "",
  description: "",
  version: "",
  license: "",
  repository: "",
  author: "",
  authors: ""
};
var USELESS_TMP_FILES = ["tsconfig.json", "typings.d.ts"];
var VERSION_2_LEVEL_NAV = "^2.2.0";
var VERSION_2_DEPRECATE_SOFT_BREAKS = "^2.2.0";
var DEFAULT_DEMO_MODULE_EXTENSIONS = [".js", ".jsx", ".ts", ".tsx"];
var DEFAULT_DEMO_PLAIN_TEXT_EXTENSIONS = [
  ".css",
  ".less",
  ".sass",
  ".scss",
  ".styl",
  ".json"
];
var FS_CACHE_DIR = "node_modules/.cache/dumi";
var SHOULD_SKIP_LIVEDEMO_ERROR = [
  "Unable to find node on an unmounted component",
  "#188",
  "Portals are not currently supported by the server renderer",
  "#257"
];
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  DEFAULT_DEMO_MODULE_EXTENSIONS,
  DEFAULT_DEMO_PLAIN_TEXT_EXTENSIONS,
  FS_CACHE_DIR,
  LOCAL_DUMI_DIR,
  LOCAL_PAGES_DIR,
  LOCAL_THEME_DIR,
  PICKED_PKG_FIELDS,
  PREFERS_COLOR_ATTR,
  PREFERS_COLOR_LS_KEY,
  SHOULD_SKIP_LIVEDEMO_ERROR,
  SP_ROUTE_PREFIX,
  THEME_PREFIX,
  USELESS_TMP_FILES,
  VERSION_2_DEPRECATE_SOFT_BREAKS,
  VERSION_2_LEVEL_NAV
});
